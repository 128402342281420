export const ApiUrl = "https://admin.yashodaseeds.com/api";


export const BaseUrl = "https://admin.yashodaseeds.com/support/public/upload/";

// export const ApiUrl = "https://abc.brandwizz.in/api";
// export const BaseUrl = "https://abc.brandwizz.in/support/public/upload/";

// export const ApiUrl = "http://127.0.0.1:8000/api";
// export const BaseUrl = "http://127.0.0.1:8000/public/upload/";

    