import React from 'react'
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import '../utitls/LoginPopUp.css'
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../utitls/config.js';
import btnicon from '../../assets/images/btn-icons/elements-01.png'
import loginImg from '../../assets/images/Login_img.jpg'
// assets/images/btn-icons/elements-01.png
export const LoginPopup = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOTP] = useState('');
    const [isOTPVerified, setIsOTPVerified] = useState(false);
    const [UserRegisterMsg, setUserRegisterMsg] = useState();
    const [alertState, setAlertState] = useState();
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [errColor,seterrColor] = useState();
    const [errFontColor,seterrFontColor] = useState();
    
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(e.target.value);
        const phoneRegex = /^\d{10}$/;
        setIsValidPhoneNumber(phoneRegex.test(value));
    };

    useEffect(() => {
        const handleLoginPopup = (event) => {
            // console.log("*************");
            setAlertState({ assembly1: event.detail.assembly1 });
            // You can access event data using event.detail, e.g., event.detail.assembly1
            // setAlertState({ assembly1: event.detail.assembly1 });
        }

        window.addEventListener('LoginPopup', handleLoginPopup);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('LoginPopup', handleLoginPopup);
        };
    }, []);
    const onPopupClose = () => {
        setAlertState(undefined);
    }
    const handleSendOTP = async () => {
        let sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            sessionId = `session_${Date.now()}_${Math.random().toString(36).substring(7)}`;
            localStorage.setItem('sessionId', sessionId, { expires: 365 }); // Expires in 365 days
            localStorage.setItem('sessionId', sessionId);
        }
        const LoginDetails = {
            phoneNumber: phoneNumber,
            UserSessionID: sessionId,
        };
        axios.post(`${ApiUrl}/save-login-details`, LoginDetails)
            .then(response => {
                if (response.data == '1') {
                    setUserRegisterMsg('Enter the OTP sent to your phone');
                    seterrColor('green');
                    seterrFontColor('black');
                    setIsOTPVerified(true);
                } else if (response.data == '2') {
                    setUserRegisterMsg('Enter the OTP sent to your phone');
                    seterrColor('green');
                    seterrFontColor('black');
                    setIsOTPVerified(true);
                } else {
                    setUserRegisterMsg('There is some problem. please try again');
                    seterrColor('red');
                    seterrFontColor('white');
                    setIsOTPVerified(false);
                }
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
        // setIsOTPVerified(true);
    };
    const handleOTPChange = (e) => {
        setOTP(e.target.value);
    };
    const handleVerifyOTP = async () => {
        let sessionId = localStorage.getItem('sessionId');
        // const history = useHistory();
        const LoginVerify = {
            phoneNumber: phoneNumber,
            UserSessionID: sessionId,
            OTP: otp,
        };
        axios.post(`${ApiUrl}/verify-login-details`, LoginVerify)
            .then(response => {
                if (response.data == '1') {
                    setUserRegisterMsg('OTP Match');
                    setIsOTPVerified(true);
                    localStorage.setItem('IsLogin', '1');
                    localStorage.setItem('CustomerPhoneNo', phoneNumber);
                    setAlertState(undefined);
                    seterrColor('green');
                    seterrFontColor('black');
                    window.location.reload(false);
                    // history.push('/dashboard');
                } else if (response.data == '2') {
                    setUserRegisterMsg('OTP Mismatch');
                    seterrColor('red');
                    seterrFontColor('white');
                    setIsOTPVerified(true);
                    localStorage.setItem('IsLogin', '0');
                } else {
                    setUserRegisterMsg('There is some problem. please try again');
                    seterrColor('red');
                    seterrFontColor('white');
                    localStorage.setItem('IsLogin', '0');
                    setIsOTPVerified(false);
                }
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
        const isVerified = true;
        if (isVerified) {
            setIsOTPVerified(true);
        }
    };
    return (alertState &&
        <div className='pop-bg'>
            <div className="container custPopSou body-login">
                <div onClick={onPopupClose}><i class="fa-solid fa-circle-xmark" ></i></div>
                <div className="card card0">
                    <div className="d-flex flex-lg-row flex-column-reverse">
                        <div className="card card2 d-none d-md-block">
                            <div className=""><img src={loginImg} /></div>
                        </div>
                        <div className="card card1">
                            <div className="row justify-content-center my-auto">
                                <div className="col-md-8 col-10">
                                    <h3 className="mb-5 text-center heading">Log In here</h3>
                                    <h6 className="msg-info">Please login to your account</h6>
                                    {!isOTPVerified ? (
                                        <>
                                            <div className="form-group">
                                                <label className="form-control-label">Phone Number</label>
                                                <input type="text" id="email" name="email" placeholder="Phone Number" className="form-control" value={phoneNumber} onChange={handlePhoneNumberChange} />
                                                {!isValidPhoneNumber && (<p style={{ color: 'red' }}>Please enter a valid 10-digit phone number.</p>)}
                                            </div>
                                            <div className="row justify-content-center my-3 px-3">
                                                <div className="btn-read-more btn-submit-details">
                                                    <button type="submit" className="link-primary" onClick={handleSendOTP}  disabled={!isValidPhoneNumber}>
                                                        Send OTP <span><img src={btnicon} className="btn-green" /></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{backgroundColor: errColor,color:errFontColor}}>{UserRegisterMsg}</span>
                                            <div className="form-group">
                                                <label className="form-control-label" style={{color:errColor}}>OTP</label>
                                                <input type="password" id="psw" name="psw" placeholder="OTP" className="form-control" value={otp} onChange={handleOTPChange} />
                                            </div>
                                            <div className="row justify-content-center my-3 px-3">
                                                <div className="btn-read-more btn-submit-details">
                                                    <button type="submit" onClick={handleVerifyOTP} className="link-primary">Verify OTP <span><img src={btnicon} className="btn-green" /></span></button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>

                            </div>
                            {/* <div className="bottom text-center btn-account-create">
                                <p href="#" className="sm-text mx-auto mb-3">Don't have an account? <a href="javascript:void(0)" className="btn-create">Create a new account</a></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export const myPopup = {
    open: (assembly1) => {
        // console.log("Souvik Dasgupta");
        const selectionFired = new CustomEvent("LoginPopup", {
            "detail": { assembly1 }
        });
        window.dispatchEvent(selectionFired);

    }

}